/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import FormField from "../../../../../components/FormField";
import MDBox from "../../../../../components/MDBox";
import React from "react";
// Material Dashboard 2 PRO React components


function EditUserForm({formData, roles}) {

    const {formField, values, errors, touched} = formData;

    const {name, email, role_id, password} = formField;
    const {
        name: nameV,
        email: emailV,
        role_id: roleIdV,
        password: passwordV,
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={ nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormField
                        type={email.type}
                        label={email.label}
                        name={email.name}
                        value={emailV}
                        error={errors.email && touched.email}
                        success={emailV && emailV.length > 0 && !errors.email}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={role_id.type}
                        label={role_id.label}
                        name={role_id.name}
                        value={roleIdV}
                        options={roles}
                        error={errors.role_id && touched.role_id}
                        success={roleIdV && roleIdV.length > 0 && !errors.role_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={password.type}
                        label={password.label}
                        name={password.name}
                        value={passwordV}
                        error={errors.password && touched.password}
                        success={passwordV && passwordV.length > 0 && !errors.password}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default EditUserForm;
