/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "../../../../../components/MDBox";
import FormField from "../../../../../components/FormField";
import React from "react";
import MDTypography from "../../../../../components/MDTypography";
// Material Dashboard 2 PRO React components


function NewBrandForm({formData}) {

    const {formField, values: val, errors, touched} = formData;

    const {name, tone, values} = formField;
    const {
        name: nameV,
        tone: toneV,
        values: valuesV
    } = val;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={tone.type}
                        label={tone.label}
                        name={tone.name}
                        value={toneV}
                        error={errors.tone && touched.tone}
                        success={toneV && toneV.length > 0 && !errors.tone}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={values.type}
                        label={values.label}
                        name={values.name}
                        value={valuesV}
                        error={errors.values && touched.values}
                        success={valuesV && valuesV.length > 0 && !errors.values}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default NewBrandForm;
