/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React base styles
import borders from "assets/theme-dark/base/borders";
import colors from "assets/theme-dark/base/colors";

// Material Dashboard 2 PRO React helper functions
import pxToRem from "assets/theme-dark/functions/pxToRem";
import typography from "../../base/typography";


const { borderWidth } = borders;
const { light, text, background, primary } = colors;

const tableCell = {
  styleOverrides: {
    root: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      // borderBottom: `${borderWidth[1]} solid ${light.main}`,
      backgroundColor: background.card,
      color: 'white'
    },
    body: {
      fontSize: typography.size.sm,
      color: 'white',
      '& a': {
        color: primary.main,
        textDecoration: 'underline !important'
      }
    },
    head: {
      color: colors.secondary.main,
      fontSize: typography.size.xxs,
      fontWeight: typography.fontWeightBold,
      textTransform: "uppercase",
      '& div': {
        alignItems: 'center',
        '& div': {
          color: colors.secondary.main
        }
      },
      '& .MuiButton-root': {
        color: colors.secondary.main,
        fontSize: typography.size.xxs,
        fontWeight: typography.fontWeightBold,
        textTransform: "uppercase",
        '& div': {
          alignItems: 'center',
          '& div': {
            color: colors.secondary.main
          }
        }
      }
    },
    footer: {
      color: 'white'
    }
  },
};

export default tableCell;
