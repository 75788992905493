import React, {createContext, useContext, useState} from "react";
import MDSnackbar from "../../components/MDSnackbar";

const SnackbarContext = createContext();

export const useSnackbar = () => {
    return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    const showSnackbar = (title, message, icon, color) => {

        setSnackbarConfig({
            message,
            icon,
            title,
            color
        });


        setShow(true);

    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </SnackbarContext.Provider>
    );
};
