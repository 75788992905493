/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "edit-user-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      errorMsg: "Email is required.",
      invalidMsg: "Email is not valid.",
    },
    role_id: {
      name: "role_id",
      label: "Role",
      type: "select",
      errorMsg: "Role is required.",
      invalidMsg: "Role is required."
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Password must have at least 8 characters",
    },
  },
};

export default form;
