/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "recover-password-form",
  formField: {
    token: {
      name: 'token',
      type: 'hidden'
    },
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "john@email.com",
      errorMsg: "Email address is required.",
      invalidMsg: "Email address is invalid",
    },
    password: {
      name: "password",
      label: "New Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Password must be at least 8 characters long",
    },
    password_confirmation: {
      name: "password_confirmation",
      label: "Confirm New Password",
      type: "password",
      errorMsg: "Password confirmation is required.",
      invalidMsg: "Passwords did not match.",
    }
  },
};

export default form;
