/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "generator-form",
  formField: {
    name: {
      name: "name",
      label: "Product Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    style: {
      name: "style",
      label: "Style Number",
      type: "text",
      errorMsg: "Style number is required.",
    },
    details: {
      name: "details",
      label: "Product Details",
      type: "text",
      errorMsg: "Details are required.",
    },
    product_type_id: {
      name: "product_type_id",
      label: "Product Type",
      type: "select",
      errorMsg: "Type is required.",
      invalidMsg: "Type is required."
    }
  },
};

export default form;
