/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Formik
import {ErrorMessage, Field} from "formik";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import MDDatePicker from "../MDDatePicker";
import MDEditor from "../MDEditor";
import Dropzone from "../Dropzone";
import Autocomplete from "@mui/material/Autocomplete/Autocomplete";
import MDAutocomplete from "../MDAutocomplete";

function FormField({label, name, type, options, ...rest}) {
    return (
        <MDBox mb={1.5}>
            {
                type === 'date' &&
                <Field {...rest} name={name} options={options} component={MDDatePicker} input={{label: label, fullWidth: true}}/>
            }
            {
                (type === 'text' || type === 'number' || type === 'email' || type === 'password') &&
                <Field {...rest} name={name} type={type} label={label} as={MDInput} fullWidth/>
            }
            {
                type === 'editor' &&
                <>
                    <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                            {label}
                        </MDTypography>
                    </MDBox>
                    <Field {...rest} name={name} type={type} component={MDEditor} fullWidth/>
                </>
            }
            {
                type === 'checkbox' &&
                <Field
                    as={FormControlLabel}
                    type={type}
                    name={name}
                    label={label}
                    control={<Checkbox/>}
                />
            }
            {
                type === 'file' &&
                <>
                    <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                            {label}
                        </MDTypography>
                    </MDBox>
                    <Field {...rest} name={name} component={Dropzone} />
                </>
            }
            {
                type === 'select' &&
                <Field
                    {...rest}
                    name={name}
                    label={label}
                    options={options}
                    component={MDAutocomplete}
                />

            }
            <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                    <ErrorMessage name={name}/>
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

// typechecking props for FormField
FormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
};

export default FormField;
