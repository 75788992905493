import React, {useState} from "react";
import {capitalizeFirstLetter} from "../../../helpers/helpers";
import Chip from "@mui/material/Chip";
import {useNavigate} from "react-router-dom";

const useUsers = () => {

    const [filters, setFilters] = useState(null);

    let navigate = useNavigate();

    const actions = {
        add: () => navigate("/users/new-user"),
        edit: (id) => navigate(`/users/${id}/edit-user`),
        delete: true,
    };

    const renderRoleChip = (status) => {
        switch (status) {
            case 'admin':
                return <Chip size="small" color="info" label={capitalizeFirstLetter(status)}
                             variant="outlined"/>;
            case 'reception':
                return <Chip size="small" color="primary" label={capitalizeFirstLetter(status)}
                             variant="outlined"/>;
            case 'host':
                return <Chip size="small" color="secondary" label={capitalizeFirstLetter(status)}
                             variant="outlined"/>;
            case 'manager':
                return <Chip size="small" color="warning" label={capitalizeFirstLetter(status)}
                             variant="outlined"/>;
        }
    };

    const renderStatusChip = (status) => {
        switch (status) {
            case 1:
                return <Chip size="small" color="secondary" label="Active"
                             variant="outlined"/>;
            case 0:
                return <Chip size="small" color="warning" label="Inactive"
                             variant="outlined"/>;
        }
    };

    return {
        actions, filters, setFilters, renderRoleChip, renderStatusChip
    }
};

export default useUsers;
