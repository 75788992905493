import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../config";
import {capitalizeFirstLetter} from "../../helpers/helpers";
import validations from "./schemas/validations";
import form from "./schemas/form";

const useGenerator = () => {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [isLoading, setIsLoading] = useState(true);

    const [productTypes, setProductTypes] = useState([]);
    const [productCopy, setProductCopy] = useState(null);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            await loadProductTypes();
        };

        init();

    }, []);

    const loadProductTypes = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/product_types`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setProductTypes([{id: -1, label: "Choose an option"}, ...response.data.map(productType => ({id: productType.id, label: productType.name}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the brands",
                icon: 'cancel',
                title: 'Product Types',
                color: 'warning'
            });

            setShow(true);

        }

        setIsLoading(false);
    };

    const handleCopy = () => {

        setSnackbarConfig({
            message: 'Copied to clipboard',
            icon: 'notifications',
            title: 'Copied',
            color: 'secondary'
        });

        setShow(true);

    };

    const handleSubmit = async (values, actions) => {

        try {

            let data = {...values};
            data.name = data.name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');


            // Enviar datos a API
            const response = await axios.post(
                `${API_URL}/generator`,
                data
            );

            setProductCopy({
                productDescription: response.data.description,
                seoTitle: response.data.seo_title,
                seoDescription: response.data.seo_description,
                // tags: response.data.tags
            });

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Generator',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        productTypes,
        productCopy,
        currentValidation,
        formId,
        formField,
        isLoading,
        show,
        snackbarConfig,
        handleSubmit,
        handleCopy,
        toggleSnackbar
    };
};

export default useGenerator;
