/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import {Form, Formik} from "formik";
import React from "react";
import useGenerator from "./useGenerator";
import BaseLayout from "../../components/BaseLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import GeneratorForm from "./components/GeneratorForm/GeneratorForm";
import MDSnackbar from "../../components/MDSnackbar";
import initialValues from "./schemas/initialValues";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Icon} from "@mui/material";

// Settings page components

const Generator = () => {

    const {productTypes, productCopy, show, currentValidation, formId, formField, isLoading, snackbarConfig, handleSubmit, handleCopy, toggleSnackbar} = useGenerator();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} lg={6}>
                    <MDBox mb={3}>
                        <Card id="generator" sx={{overflow: "visible"}}>
                            {
                                isLoading ?
                                    <MDBox mt={2} mx={2} p={10} display="flex" justifyContent="center"
                                           alignItems="center">
                                        <CircularProgress color="secondary" style={{overflow: 'hidden'}}/>
                                    </MDBox> :
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={currentValidation}
                                        onSubmit={handleSubmit}
                                    >
                                        {({values, errors, touched, isSubmitting}) => (
                                            <Form id={formId} autoComplete="off">
                                                <MDBox p={3}>
                                                    <MDTypography variant="h5">Generator</MDTypography>
                                                    <MDTypography variant="body2">Enter the product's details.</MDTypography>
                                                </MDBox>
                                                <MDBox pb={3} px={3}>
                                                    <GeneratorForm productTypes={productTypes} formData={{values, touched, formField, errors}} />
                                                    <MDBox mt={2} width="100%" display="flex"
                                                           justifyContent="flex-end">
                                                        <MDBox>
                                                            <MDButton
                                                                disabled={isSubmitting}
                                                                variant="gradient"
                                                                color="secondary"
                                                                type="submit"
                                                            >
                                                                {isSubmitting &&
                                                                <CircularProgress size={18} color="white"
                                                                                  sx={{marginRight: 1}}/>}
                                                                Generate
                                                            </MDButton>
                                                        </MDBox>
                                                    </MDBox>
                                                </MDBox>

                                            </Form>
                                        )}
                                    </Formik>
                            }
                        </Card>
                    </MDBox>
                </Grid>
                <Grid item xs={12} lg={6}>

                    {
                        productCopy !== null &&
                        <>
                            <Card style={{padding: 24, marginBottom: 16}}>
                                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <MDTypography variant="h5">Product Description</MDTypography>
                                    </Grid>
                                    <Grid item>
                                        <CopyToClipboard text={productCopy.productDescription} onCopy={handleCopy}>
                                            <MDButton size="small" variant="outlined" color="secondary" startIcon={<Icon>content_copy</Icon>}>Copy</MDButton>
                                        </CopyToClipboard>
                                    </Grid>
                                </Grid>
                                <div style={{color: "white", fontSize: 14, marginTop: 10}} dangerouslySetInnerHTML={{ __html: productCopy.productDescription }}>

                                </div>
                            </Card>
                            <Card style={{padding: 24, marginBottom: 16}}>
                                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <MDTypography variant="h5">Product SEO Title</MDTypography>
                                    </Grid>
                                    <Grid item>
                                        <CopyToClipboard text={productCopy.seoTitle} onCopy={handleCopy}>
                                            <MDButton size="small" variant="outlined" color="secondary" startIcon={<Icon>content_copy</Icon>}>Copy</MDButton>
                                        </CopyToClipboard>
                                    </Grid>
                                </Grid>
                                <MDTypography variant="body2" mt={2}>{productCopy.seoTitle}</MDTypography>
                            </Card>
                            <Card style={{padding: 24, marginBottom: 16}}>
                                <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <MDTypography variant="h5" mb={2}>Product SEO Meta Description</MDTypography>
                                    </Grid>
                                    <Grid item>
                                        <CopyToClipboard text={productCopy.seoDescription} onCopy={handleCopy}>
                                            <MDButton size="small" variant="outlined" color="secondary" startIcon={<Icon>content_copy</Icon>}>Copy</MDButton>
                                        </CopyToClipboard>
                                    </Grid>
                                </Grid>
                                <MDTypography variant="body2" mt={2}>{productCopy.seoDescription}</MDTypography>
                            </Card>
                            {/*<Card style={{padding: 24, marginBottom: 16}}>*/}
                            {/*    <Grid container spacing={3} direction="row" justifyContent="space-between" alignItems="center">*/}
                            {/*        <Grid item>*/}
                            {/*            <MDTypography variant="h5" mb={2}>Product Tags</MDTypography>*/}
                            {/*        </Grid>*/}
                            {/*        <Grid item>*/}
                            {/*            <CopyToClipboard text={productCopy.tags} onCopy={handleCopy}>*/}
                            {/*                <MDButton size="small" variant="outlined" color="secondary" startIcon={<Icon>content_copy</Icon>}>Copy</MDButton>*/}
                            {/*            </CopyToClipboard>*/}
                            {/*        </Grid>*/}
                            {/*    </Grid>*/}
                            {/*    <MDTypography variant="body2" mt={2}>{productCopy.tags}</MDTypography>*/}
                            {/*</Card>*/}
                        </>
                    }
                </Grid>
            </Grid>
            <MDSnackbar
                color={snackbarConfig.color}
                icon={snackbarConfig.icon}
                title={snackbarConfig.title}
                content={snackbarConfig.message}
                dateTime=""
                autoHideDuration={3000}
                open={show}
                close={toggleSnackbar}
            />
        </DashboardLayout>
    );
};

export default Generator;
