/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "sign-in-form",
  formField: {
    email: {
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "john@email.com",
      errorMsg: "Email is required.",
      invalidMsg: "Email is not valid.",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
    }
  },
};

export default form;
