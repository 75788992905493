import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import { withStyles } from "tss-react/mui";
import Icon from "@mui/material/Icon";

const defaultToolbarStyles = {
    iconButton: {
    },
};

const CustomToolbar = ({ classes, canAdd = true, handleNewClick, customActions = [] }) => {

    const renderCustomActions = () => {
        return customActions.map((action, index) => (
            <Tooltip key={index} title={action.label}>
                <IconButton className={classes.iconButton} onClick={action.action}>
                    <Icon className={classes.deleteIcon}>{action.icon}</Icon>
                </IconButton>
            </Tooltip>
        ))
    };

    return (
        <React.Fragment>
            {
                canAdd && <Tooltip title={"Add New"}>
                    <IconButton className={classes.iconButton} onClick={handleNewClick}>
                        <AddIcon className={classes.deleteIcon} color="secondary"/>
                    </IconButton>
                </Tooltip>
            }
            {renderCustomActions()}
        </React.Fragment>
    );

};

export default withStyles(CustomToolbar, defaultToolbarStyles, { name: "CustomToolbar" });
