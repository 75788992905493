import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MUIDataTable from "mui-datatables";
import React from "react";
import useDataTable from "./useDataTable";
import {API_URL} from "../../config";
import MDSnackbar from "../MDSnackbar";
import LinearProgress from "@mui/material/LinearProgress/LinearProgress";

const DataTable = ({endpoint, title, description, colData, sortDirection, sortBy, filters, actions, filter = true, search = true, loadAtStart = true, onSortChange = null, serverSide, forceLoad, rowsPerPage = 10}) => {

    const {isLoading, options, columns, data, renderTitle, renderIntro} = useDataTable({
        endpoint,
        title,
        description,
        sortDirection,
        sortBy,
        colData,
        parentFilters: filters,
        actions,
        filter,
        canSearch: search,
        loadAtStart,
        onSortChange,
        serverSide,
        forceLoad,
        rowsPP: rowsPerPage
    });

    return <>
        {
            isLoading ?
                <MDBox mt={2} mx={2}>
                    <LinearProgress color="secondary" style={{overflow: 'hidden'}}/>
                </MDBox> :
                null
        }
        <MUIDataTable
        title={
            <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium">
                    {renderTitle()}
                </MDTypography>
                <MDTypography variant="button" >
                    {renderIntro()}
                </MDTypography>
            </MDBox>
        }
        data={data}
        columns={columns}
        options={options}
    />
    </>
};

export default DataTable;
