
export const ENVIRONMENT = 'PRODUCTION';

const getAppUrl = () => {
    if(ENVIRONMENT === 'LOCAL')
        return ('http://localhost:8000');

    if(ENVIRONMENT === 'STAGING')
        return 'https://api.brandbard.astwarehosting.com';

    if(ENVIRONMENT === 'PRODUCTION')
        return 'https://api.brandbard.astwarehosting.com';
};

export const APP_URL = getAppUrl();
export const API_URL = APP_URL + '/api';
