import form from "./schemas/form";
import validations from "./schemas/validations";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../../../config";
import {capitalizeFirstLetter} from "../../../helpers/helpers";

const useNewUser = () => {

    const {formId, formField} = form;
    const currentValidation = validations;

    const [isLoading, setIsLoading] = useState(true);

    const [roles, setRoles] = useState([]);

    const [snackbarConfig, setSnackbarConfig] = useState({
        color: 'secondary',
        title: '',
        message: '',
        icon: 'notifications'
    });
    const [show, setShow] = useState(false);
    const toggleSnackbar = () => setShow(!show);

    useEffect(() => {

        const init = async () => {
            await loadRoles();
        };

        init();

    }, []);

    const loadRoles = async () => {

        setIsLoading(true);

        try {

            const response = await axios.get(`${API_URL}/roles`, {
                params: {
                    sort: 'name',
                    direction: 'asc',
                    active: true
                }
            });

            if(response.data.length > 0)
                setRoles([{id: -1, label: "Choose an option"}, ...response.data.map(role => ({id: role.id, label: capitalizeFirstLetter(role.name)}))]);

        } catch (error) {

            setSnackbarConfig({
                message: "Something went wrong loading the roles",
                icon: 'cancel',
                title: 'Roles',
                color: 'warning'
            });

            setShow(true);

        }

        setIsLoading(false);
    };

    const handleSubmit = async (values, actions) => {

        try {

            // Enviar datos a API
            await axios.post(
                `${API_URL}/users`,
                {...values}
            );

            actions.resetForm();

            setSnackbarConfig({
                message: 'User saved successfully',
                icon: 'notifications',
                title: 'Users',
                color: 'secondary'
            });

            setShow(true);

        } catch (error) {

            setSnackbarConfig({
                message: error.response.data.message,
                icon: 'cancel',
                title: 'Users',
                color: 'warning'
            });

            setShow(true);

        }

        actions.setSubmitting(false);

    };

    return {
        currentValidation,
        formId,
        formField,
        isLoading,
        show,
        roles,
        snackbarConfig,
        handleSubmit,
        toggleSnackbar
    };
};

export default useNewUser;
