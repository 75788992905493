/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid";
import React from "react";
import MDBox from "../../../../components/MDBox";
import FormField from "../../../../components/FormField";
// Material Dashboard 2 PRO React components


function GeneratorForm({formData, productTypes}) {

    const {formField, values, errors, touched} = formData;

    // const {name, details, product_type_id} = formField;
    const {name, style, details, product_type_id} = formField;

    const {
        name: nameV,
        details: detailsV,
        style: styleV,
        product_type_id: productTypeIdV
    } = values;

    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={product_type_id.type}
                        label={product_type_id.label}
                        name={product_type_id.name}
                        value={productTypeIdV}
                        options={productTypes}
                        error={errors.product_type_id && touched.product_type_id}
                        success={productTypeIdV && productTypeIdV.length > 0 && !errors.product_type_id}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={name.type}
                        label={name.label}
                        name={name.name}
                        value={nameV}
                        error={errors.name && touched.name}
                        success={nameV && nameV.length > 0 && !errors.name}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={style.type}
                        label={style.label}
                        name={style.name}
                        value={styleV}
                        error={errors.style && touched.style}
                        success={styleV && styleV.length > 0 && !errors.style}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormField
                        type={details.type}
                        label={details.label}
                        name={details.name}
                        value={detailsV}
                        error={errors.details && touched.details}
                        multiline={true}
                        rows={10}
                        success={detailsV && detailsV.length > 0 && !errors.details}
                    />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default GeneratorForm;
